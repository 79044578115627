
interface MyWindow extends Window {
    mozRequestAnimationFrame: any
    oRequestAnimationFrame: any
    msRequestAnimationFrame: any
  }
  declare var window: MyWindow;

  window.requestAnimationFrame = (function() {
    return (
        window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        window.oRequestAnimationFrame      ||
        window.msRequestAnimationFrame     ||
        function(callback:any, time:number) {
          let Time = time ? time: 1000 / 60;
          window.setTimeout(callback, Time);
      }
    );
  })();


class Particles {
    constructor() {}
    public main(wrap:string,element:string,color:string,
        densitynumber:number,speednumber:number,Size:number){

        let canvasWrap:any = document.querySelector(wrap);
        let canvas:any = document.querySelector(element);
        let ctx = canvas.getContext('2d');

        let dots:any = [];
        let density = densitynumber;
        let colors = [color];
        let baseSize = Size;
        let baseSpeed = speednumber;

        let Dot = function (this:any) {
            this.size = Math.floor( Math.random() * 6 ) + baseSize;
            this.color = colors[~~(Math.random() * 3)];
            this.speed = this.size / baseSpeed;
            this.pos = {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height
            };
            let max = -120
            let min = -70
            let rot = Math.random() * ( (max) - (min) ) + (min);
            let angle = rot * Math.PI / 180;
            this.vec = {
                x: Math.cos(angle) * this.speed,
                y: Math.sin(angle) * this.speed
            };
        } as any;
        Dot.prototype = {
            update: function() {
                this.draw();
                this.pos.x += this.vec.x;
                this.pos.y += this.vec.y;
                if(this.pos.x > canvas.width + 10) {
                    this.pos.x = -5;
                } else if(this.pos.x < 0 - 10) {
                    this.pos.x = canvas.width + 5;
                } else if(this.pos.y > canvas.height + 10) {
                    this.pos.y = -5;
                } else if(this.pos.y < 0 - 10) {
                    this.pos.y = canvas.height + 5;
                }
            },
            draw: function() {
                ctx.fillStyle = this.color;
                ctx.beginPath();
                ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
                ctx.fill();
            }
        };

        function update() {
            requestAnimationFrame(update);
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            for (let i = 0; i < density; i++) {
                dots[i].update();
            }
        }

        function init() {
            canvas.setAttribute("width", canvasWrap.offsetWidth);
            canvas.setAttribute("height", canvasWrap.offsetHeight);
            for (let i = 0; i < density; i++) {
                dots.push(new Dot());
            }
            update();
        }
        init();

    }
}

export default Particles