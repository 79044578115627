import Add_style from './app_add_style';
const add_style = new Add_style();


class G_header {
  constructor(public $: any) {
    this.$ = $
  }

  public global_follow_header($: any): void {
    let scroll_num: number;
    let scroll_go: string = "scroll-change-state";
    let global_header: any;
    let flag: boolean = false;
    global_header = $("#global-header");
    let main_comp = global_header;

    let scroll_header = () => {
      let main_comp_top = main_comp.innerHeight();
      let main_comp_num = Math.floor(main_comp_top);
      scroll_num = $(window).scrollTop();
      if (scroll_num > main_comp_num) {
        global_header!.addClass(scroll_go);
      } else {
        global_header!.removeClass(scroll_go);
      }
    }

    window.addEventListener("load", function () {
      flag = true;
      if (flag) {
        scroll_header();
      }
    });

    window.addEventListener("scroll", function () {
      if (flag) {
        flag = false;
        setTimeout(function () {
          scroll_header();
          flag = true;
          return flag;
        }, 0);

      }

    });

  }

  public global_spHeader_fun(scrollnum:any): void {
    const $ = this.$
    const spMenuWrap = document.getElementById("menu_trigger");
    const menu_list:any = document.getElementsByClassName("menu-list")[0];
    const addBodyStyle = $('body')
    const BODY: any = document.getElementsByTagName("body")[0];



    let delete_element = function (num: string): void {
      let re_px;
      let re_fix;
      let type_elm;
      console.log(num)

      if (typeof num === "string") {
        re_px = num.replace("px", "");
        re_fix = re_px.replace("-", "");
        type_elm = Number(re_fix);
        window.scrollTo(0, type_elm);
      }
    }

    let stop = function (): void {
      let body_style = BODY.getAttribute("style").replace("top:", "");
      delete_element(body_style);
    }



    if (spMenuWrap!.classList.contains('on')) {
      G_header.menuOff(spMenuWrap)
      menu_list.classList.remove("on");
      addBodyStyle.removeClass('scrollstop')
      stop();
    } else {
      G_header.menuOn(spMenuWrap)
      menu_list.classList.add("on");
      addBodyStyle.addClass('scrollstop')
      add_style.add_style_css(BODY, { top: "-" + scrollnum + "px" });
    }

  }

  static menuOn(open: any) {
    open.classList.add('on')
  }
  static menuOff(open: any) {
    open.classList.remove('on')
  }
}

export default G_header
