import '@babel/polyfill'
import 'objectFitPolyfill'
import 'retinajs';
import './components/css_browser_selecter.js'
import $ from 'jquery'
import Dot from './components/particles_anim'
import PerfectScrollbar from "perfect-scrollbar"
import G_header from './components/global_header'
import Accodion from './components/acco'
import Swiper from 'swiper/js/swiper.min.js'
import Top_swiper from './components/top_swiper'
import Blog_top_swiper from './components/blog-top_swiper'
import Page_scroll from './components/page_scroll';
import * as Cookies from "js-cookie";
import * as Stickyfill from 'stickyfilljs/dist/stickyfill.min.js'

const global_header = new G_header($)
const app_acco = new Accodion()
const app_particles = new Dot()
const app_Page_scroll = new Page_scroll($);

app_particles.main('.background05','.background05_peticle05','#108ab7',20,20,6);



const headerH = 110; //ヘッダーの高さ
$(window).on("load", function(){
  if(location.hash !== ""){
    const targetOffset = $(location.hash).offset().top;
    $(window).scrollTop(targetOffset - headerH);
  }
});

//画面幅が狭い時、追従ヘッダーを横スクロール可能にする
$(window).on("scroll", function(){
  $(".c-header").css("left", -$(window).scrollLeft());
});

let timeoutId: null | any;
let flag: boolean;

const checkWith = (...testcheck: any) => {
  let w = window.innerWidth;
  if(timeoutId) return;
  timeoutId = setTimeout(function(){
    timeoutId = null;
    if(w <= 800 && flag != false){
      flag = false
      testcheck[0]();

    }else if(w > 500 && flag != true){
      flag = true
      testcheck[0]();
    }
  },0);
}


document.addEventListener('DOMContentLoaded', function() {

  new PerfectScrollbar('.menu-list');
  app_Page_scroll.is_page_scroll();
  const wrapper = document.getElementsByClassName("wrapper")[0];
  const data_set = wrapper.getAttribute("data-slider");
  const top_swiper= new Top_swiper(Swiper);
  const blog_top_swiper= new Blog_top_swiper(Swiper);

  let scrollFunc = function() {
    let $fixElement = $('.syncpit_list-btn'); // 追従する要素
    let fixClass = 'is-fixed'; // 追従時に付与するclass
    let syncpit03 = $("#syncpit03");
    let baseFixPoint = $fixElement.offset().top; // 追従する要素の初期位置
    let syncpit03Point = syncpit03.offset().top;

    // 要素が追従する処理
    function fixFunction() {
      let windowScrolltop = $(window).scrollTop();
      // スクロールが初期位置を通過しているとき

      if(windowScrolltop >= (baseFixPoint-200)) {
        $fixElement.addClass(fixClass);
      } else {
        $fixElement.removeClass(fixClass);
      }

      if(windowScrolltop >= (syncpit03Point)) {
        $fixElement.addClass("stop");
      }else{
        $fixElement.removeClass("stop");
      }

    }

    fixFunction();
    $(window).on('scroll', function() {
      fixFunction();
    });

  };

  const cookic_set_name = "syncpit"
  const fade_wrap = document.getElementsByClassName("fade_wrap")[0];
  const fade_comment = document.getElementsByClassName("fade_comment")[0];
  let firstLoadAnime = function(){
    Cookies.set(cookic_set_name, cookic_set_name, { expires: 1 });
    fade_wrap.classList.remove("hide");
      setTimeout(() => {
        fade_comment.classList.remove("hide");

        setInterval(function(){
          bubble01();
        },4000);
        setInterval(function(){
          bubble02();
        },5000);
        bubble01();
        bubble02();

      }, 1500);
  }

  const elements = document.querySelectorAll('.social__list');
  Stickyfill.add(elements);


  if(data_set){
    if(data_set === "top"){

      if (!(Cookies.get(cookic_set_name))) {
        firstLoadAnime();
      }else{
        fade_wrap.classList.remove("motion");
        fade_wrap.classList.remove("hide");
        fade_comment.classList.remove("motion_comment");
        fade_comment.classList.remove("hide");

        setInterval(function(){
          bubble01();
        },3200);
        setInterval(function(){
          bubble02();
        },4000);

        bubble01();
        bubble02();

      }

      const anime = function(){
        app_particles.main('.background01','.background01_peticle01','#ffffff',30,10,0.2),
        app_particles.main('.background02','.background02_peticle02','#ffffff',40,10,0.8),
        app_particles.main('.background03','.background03_peticle03','#108ab7',50,10,6),
        app_particles.main('.background04','.background04_peticle04','#f6fcfe',10,20,6)
      }

      checkWith(anime);


      top_swiper.main()
      scrollFunc();

      window.addEventListener("resize", function(){
        checkWith(anime);
      });


    } else if(data_set === 'sub'){
      const anime = function(){
        app_particles.main('.background01','.background01_peticle01','#ffffff',30,10,0.2)
      }
      checkWith(anime);

      window.addEventListener("resize", function(){
        checkWith(anime);
      });

    } else if(data_set === 'download'){
      const anime = function(){
        app_particles.main('.background01','.background01_peticle01','#ffffff',30,10,0.2),
        app_particles.main('.background04','.background04_peticle04','#f6fcfe',10,20,6),
        app_particles.main('.background06','.background06_peticle06','#f6fcfe',10,20,6)
      }
      checkWith(anime);

      window.addEventListener("resize", function(){
        checkWith(anime);
      });

    } else if(data_set === 'blog-top'){

      const anime = function(){
        app_particles.main('.background01','.background01_peticle01','#ffffff',30,10,0.2)
      }
      checkWith(anime);

      window.addEventListener("resize", function(){
        checkWith(anime);
      });

      blog_top_swiper.main();
    } else if(data_set === 'about'){
      const anime = function(){
        app_particles.main('.background01','.background01_peticle01','#ffffff',30,10,0.2)
      }
      checkWith(anime);

      window.addEventListener("resize", function(){
        checkWith(anime);
      });

      const tabTriggerEls = Array.from(document.querySelectorAll('.js-tab'))
      let tabTargetEls: any = []

      for (let i = 0; i < tabTriggerEls.length; i++) {
        if (tabTriggerEls[i].classList.contains('is-active')) {
          const currentEl: any = tabTriggerEls[i]
          const targetEl: any = document.getElementById(currentEl.dataset.tabTarget)
          targetEl.classList.add('is-active')
          break
        }
      }

      for (let i = 0; i < tabTriggerEls.length; i++) {
        const currentEl: any = tabTriggerEls[i]
        const targetEl: any = document.getElementById(currentEl.dataset.tabTarget)
        tabTargetEls.push(targetEl)
      }

      tabTriggerEls.forEach((el: any) => {
        el.addEventListener('click', (e: any) => {
          const triggetEl = e.currentTarget
          const targetEl: any = document.getElementById(triggetEl.dataset.tabTarget)

          tabTriggerEls.forEach((el: any) => {
            el.classList.remove('is-active')
          })

          tabTargetEls.forEach((el: any) => {
            el.classList.remove('is-active')
          })

          triggetEl.classList.add('is-active')
          targetEl.classList.add('is-active')
        })
      })
    }
  }

  
  let num:number = 0;
  let num01:number = 0;
  let choiceFukidashi:number[] = [0,1];
  function bubble01(){
    let speech01 = $(".fukidashi_comment-box01 .fukidashi_comment01");
    speech01.removeClass("show");
    num++;
    setTimeout(function(){
      if(num >= 2){
        num = 0
      }
      speech01.eq(choiceFukidashi[num]).addClass("show");
    },800);
  }
  function bubble02(){
    let speech02 = $(".fukidashi_comment-box02 .fukidashi_comment01");
    speech02.removeClass("show");
    num01++;
    setTimeout(function(){
      if(num01 >= 2){
        num01 = 0
      }
      speech02.eq(choiceFukidashi[num01]).addClass("show");
    },1000);
  }
  
  app_acco.acco($)


  let scrollnum: number;
  function onScroll(): number {
    return scrollnum = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
  }
  window.onscroll = onScroll;
  const menuTrigger: HTMLElement | null = document.getElementById('menu_trigger')
  const sp_btn_close: HTMLElement | null = document.getElementById('sp_btn-close')
  menuTrigger!.addEventListener('click',function(e: any) {
    e.preventDefault()
    global_header.global_spHeader_fun(scrollnum)
  },false)
  sp_btn_close!.addEventListener('click',function(e: any) {
    e.preventDefault()
    global_header.global_spHeader_fun(scrollnum)
  },false)

})
