class app_add_style {
    constructor() { }

    public add_style_css(element: any, styleObj: any) {
        if (typeof styleObj === 'object') {
            let prop = [];
            for (let key in styleObj) {
                prop.push(key + ':' + styleObj[key]);
            }

            let adjust_arr_style = prop.join(';');

            element.setAttribute('style', adjust_arr_style);
        } else {
            throw new Error("css() 2nd arguments must be object.");
        }
    }
}
export default app_add_style;