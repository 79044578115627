class Top_swiper {
  constructor(public Swiper: any) {
      this.Swiper = Swiper
    }
    public main() {
      const Swiper = this.Swiper
      let swiper_play = function(){
          let option = {
            preventClicks: false,
            preventClicksPropagation: false,
            loop: true,
            autoplay: {delay: 5000, disableOnInteraction: false},
            disableOnInteraction: false,
            speed: 600,
            spaceBetween: 10,
            preloadImages: true,
            updateOnImagesReady: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          }
          new Swiper(".swiper-container--case",option);
      }

      swiper_play();
    }
}
export default Top_swiper
