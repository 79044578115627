interface def {
  this: string | Record<string, any>
}

class Is_page_scroll {
  constructor(public $: any) {
    this.$ = $
  }

  public is_page_scroll() {
    const _that = this.$
    let timer: any
    const is_page_scroll_target = document.getElementsByClassName(
      'pages-box'
    )[0]
    const global_header = _that('#global-header')
    const Bool = Boolean(is_page_scroll_target)
    let flag = false
    let headerH: any
    if (Bool) {
      flag = true
    } else {
      clearInterval(timer)
    }

    timer = setInterval(function() {
      if (flag) {
        pagesScroll()
        clearInterval(timer)
      }
    }, 0)

    function pagesScroll() {
      const clicktarget = _that('.pages-box a[href^="#"]')

      clicktarget.on('click', function(this: def) {
        const href: string | any = _that(this).attr('href')
        const hash: string | any = href == '#' || href == '' ? 'html' : href
        headerH = global_header.height()
        scrollToAnker(hash, headerH)
        return false
      })
    }

    function scrollToAnker(hash: string | any, headerH: number) {
      const target: string | any = _that(hash)
      const position: string | any = target.offset().top
      _that('body,html')
        .stop()
        .animate({ scrollTop: position - headerH }, 500)
    }
  }
}
export default Is_page_scroll
