class blog_top_swiper {
    constructor(public Swiper: any) {
        this.Swiper = Swiper
    }
    public main() {
        const Swiper = this.Swiper
        let swiper_play = function(){
            let option = {
                preventClicks: false,
                preventClicksPropagation: false,
                loop: true,
                autoplay: {delay: 5000, disableOnInteraction: false},
                disableOnInteraction: false,
                speed: 600,
                spaceBetween: 10,
                preloadImages: true,
                updateOnImagesReady: true,
                navigation: {
                    nextEl: '.panel-pickup__btn--next',
                    prevEl: '.panel-pickup__btn--prev',
                },
                pagination: {
                    el: '.panel-pickup__pagination',
                    type: 'bullets',
                    clickable: 'true'
                },
            }
            new Swiper(".swiper-container--pickup",option);
        }

        swiper_play();
    }
}
export default blog_top_swiper
