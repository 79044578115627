interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T
}

class Accodion {
  constructor() {}

  public acco($: any) {
    const acco_only = $('.box_inner--acco')
    acco_only.wrap('<div class="acco_contents"></div>')
    const faq_label = $('.acco-open')
    let nextElem: any
    const ClickTargetLen: number = faq_label.length
    let i: number
    const first_style = $('.acco_contents')
    first_style.css('height', '0')
    let clienth: any


    for (i = 0; i < ClickTargetLen; i++) {
      faq_label[i].addEventListener(
        'click',
        function(e: HTMLElementEvent<HTMLInputElement>) {
          e.preventDefault()
          const target_push = $(e.target)
          nextElem = target_push.parent().next()
          clienth = nextElem.find('.box_inner--acco').innerHeight()

          if (nextElem.hasClass('open')) {
            target_push.removeClass('toggle')
            nextElem.removeClass('open')
            nextElem.css('height', '0')
          } else {
            target_push.addClass('toggle')
            nextElem.addClass('open')
            nextElem.css('height', clienth + 'px')
          }
          return
        },
        false
      )
    }
  }
}
export default Accodion
